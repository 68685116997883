// Matches the voice token with the precomputed source
// Lipsync buttons on TTS results will only show if that voice token is in this map

export const LipsyncTokenMap: { [key: string]: string } = {
  // Cristiano Ronaldo
  weight_t7a4br08b76btnnwkb32tsh7q: "m_n55cyxxbqhshbzb8btq5zs6h0amfaq", // Ronaldo (most popular)
  weight_ren3ng1bvpktsvg58gcrv2s52: "m_n55cyxxbqhshbzb8btq5zs6h0amfaq", // Ronaldo
  weight_9y01j5jxnfqmygtz2g2scenac: "m_n55cyxxbqhshbzb8btq5zs6h0amfaq", // Ronaldo
  weight_xkpvgmzj85pmrah3nt3jf3edb: "m_n55cyxxbqhshbzb8btq5zs6h0amfaq", // Ronaldo
  // Donald Trump
  weight_ppqs5038bvkm6wc29w0xfebzy: "m_vrqwr74atwg9btfcbzx6d7ehzbjmsd", // Donald Trump (most popular)
  weight_x6r5w2tsxgcrrsgweva6dkrqj: "m_vrqwr74atwg9btfcbzx6d7ehzbjmsd", // Donald Trump
  weight_vrx7j407cxk45jenkrd769h9b: "m_vrqwr74atwg9btfcbzx6d7ehzbjmsd", // Donald Trump
  weight_59fz13xqa6g43dfwfnbdn56fb: "m_vrqwr74atwg9btfcbzx6d7ehzbjmsd", // Donald Trump
  weight_jazc270pdr3qe0yer61a5cvh5: "m_vrqwr74atwg9btfcbzx6d7ehzbjmsd", // Donald Trump
  weight_q24qbmxgrv2s6yka8nwdmw1aq: "m_vrqwr74atwg9btfcbzx6d7ehzbjmsd", // Donald Trump
  weight_ekwz4cqek5rrdez73rgy843ft: "m_vrqwr74atwg9btfcbzx6d7ehzbjmsd", // Donald Trump
  // Goku (live portrait video is poor quality)
  weight_txvtzmcd7jw0rg192284r1g3w: "m_e1grhfep0c0zf7m3ta7k2j256b99gw", // Son Goku. (IMITADOR Remasterizado.) (Latin American Spanish.)
  weight_hday2q4qgcybvn4ngvzjd5mpc: "m_e1grhfep0c0zf7m3ta7k2j256b99gw", // Son Goku Abriged.
  weight_jm7reg55vqn4b5v2cdn9d8w4v: "m_e1grhfep0c0zf7m3ta7k2j256b99gw", // Son Goku Abriged.
  weight_wn689844yyr08jny6jyyvkwcp: "m_e1grhfep0c0zf7m3ta7k2j256b99gw", // Goku Italian
  weight_657xnk20pcpr8asar6sgpctqh: "m_e1grhfep0c0zf7m3ta7k2j256b99gw", // Goku
  weight_gce486k6m08ew6070emm6rgpd: "m_e1grhfep0c0zf7m3ta7k2j256b99gw", // Goku
  // Kamala Harris
  weight_th73g8zcdrj8znt5zm1fhe1s0: "m_xt17chsq6hrv3tqxebmd0sssspe12a", // Kamala Harris
  // Mariano Closs
  weight_hz7g8f1j4psrsw2sv67e4y61q: "m_zx1fyp6t7n8b46swz087xkapa7s2vs", // Mariano Closs (most popular)
  weight_42axnmna7stqy9h16j5nqef6k: "m_zx1fyp6t7n8b46swz087xkapa7s2vs", // Mariano Closs
  weight_fte9rq3em7vv9rqex0f8cvwdx: "m_zx1fyp6t7n8b46swz087xkapa7s2vs", // Mariano Closs
  weight_rhfg4chgrp42bnha8kqfrtmcq: "m_zx1fyp6t7n8b46swz087xkapa7s2vs", // Mariano Closs
  // Rick Sanchez (live portrait video is poor quality)
  weight_0f762jdzgsy1dhpb86qxy4ssm: "m_3b2yhzq4na7yg724k9tm4dn0513c36", // Rick Sanchez
  weight_qfz806d82b0qmxfn3vzgmtyme: "m_3b2yhzq4na7yg724k9tm4dn0513c36", // Rick Sanchez
  weight_n99qccqtbb4fe3yj16dsfgfv4: "m_3b2yhzq4na7yg724k9tm4dn0513c36", // Rick Sanchez
  // Walter White (live portrait video looks funny)
  weight_0bsxzrwp6p77t67s8dcn607wf: "m_ft3y3vjyg2shssrqq0p51qh143pm0n", // Walter White (most popular)
  weight_x6vgpe83p2g9pxehhaq4e8823: "m_ft3y3vjyg2shssrqq0p51qh143pm0n", // Walter White
  weight_5r8ecacq30g9pnm6dvgp30f02: "m_ft3y3vjyg2shssrqq0p51qh143pm0n", // Walter White
  weight_hect298085wsksre44ykq312t: "m_ft3y3vjyg2shssrqq0p51qh143pm0n", // Walter White
  // Wednesday Addams (Jenna Ortega)
  weight_kksrk14y802808as3d6mphedm: "m_7ap2qssd4y5ew51dkx4awnng2key32", // Wednesday Addams (Jenna Ortega) (most popular)
  weight_abe03ym9bztnzvx462kkc3fak: "m_7ap2qssd4y5ew51dkx4awnng2key32", // Wednesday Addams (Jenna Ortega) "Merlina"
  weight_cspd7e4wpjnrv1ygsa19b0gff: "m_7ap2qssd4y5ew51dkx4awnng2key32", // Wednesday Addams (Jenna Ortega)
  weight_jvdn28ebg186b63hyk6z5kpv7: "m_7ap2qssd4y5ew51dkx4awnng2key32", // Wednesday Addams (Jenna Ortega)
  // Waldemaro Martínez
  weight_kwmxssf6k6ha92h3b55mh3w9q: "m_gb27fgnr2n9nh19efmskenckptvjjt", // Waldemaro Martínez (top model)
  weight_kfc9kqm18k4jc3q2j3acdzj1a: "m_gb27fgnr2n9nh19efmskenckptvjjt", // Waldemaro Martínez (top model)
};
