import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons'

export function PauseIcon() {
  return (
      <FontAwesomeIcon icon={faPause} title="Pause" />
  );
}

