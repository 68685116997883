export const featuredTtsVoiceTokens = [
  "weight_ppqs5038bvkm6wc29w0xfebzy", // Donald Trump (GptSoVits)
  "weight_th73g8zcdrj8znt5zm1fhe1s0", // Kamala Harris (GptSoVits)
  "weight_qdtx8h0mvn945md9tm2zaq422", // SpongeBob SquarePants (GptSoVits)
  //"weight_f9wz0b1amfxhj1fnxxwtwn3rq", // Zelda (Breath of the Wild) (GptSoVits)
  "weight_0f762jdzgsy1dhpb86qxy4ssm", // Rick Sanchez (Version 2.0) (TT2)
  "weight_7jk8mgwkzsycqrxmfw5q4245y", // Homer Simpson (GptSoVits)
  "weight_3k28fws0v6r1ke3p0w0vw48gm", // Eric Cartman (New) (TT2)
  "weight_dqcy6bxq1n79z5ahwpy01bvje", // Harry Potter (GptSoVits)
  "weight_msq6440ch8hj862nz5y255n8j", // IShowSpeed (TT2)
  //"weight_6jvgbqkzschw55qdg7exnx7zx", // Moist Cr1TiKaL (New) (TT2)
];
