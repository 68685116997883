import React from "react";

import { usePrefixedDocumentTitle } from "../../../../../common/UsePrefixedDocumentTitle";
import { PosthogClient } from "@storyteller/components/src/analytics/PosthogClient";
import { useDomainConfig } from "context/DomainConfigContext";

interface Props {}

function PrivacyPage(props: Props) {
  PosthogClient.recordPageview();
  usePrefixedDocumentTitle("Privacy Statement");
  const domain = useDomainConfig();

  return (
    <div>
      <div className="container pb-0 pt-5 pb-lg-5 px-md-4 px-lg-5 px-xl-3">
        <div className="d-flex flex-column">
          <h1 className=" fw-bold text-center text-lg-start">
            {domain.titlePart} Privacy Statement
          </h1>
          <h4 className="mt-1 mb-4 opacity-75 text-center text-lg-start">
            Updated: November 18, 2022
          </h4>
        </div>
      </div>

      <div className="container-panel pt-4 pb-5">
        <div className="panel p-3 p-lg-4 load-hidden mt-5 mt-lg-0">
          <h1 className="panel-title fw-bold">{domain.titlePart} and You</h1>
          <div className="py-6 d-flex flex-column gap-4">
            In simple terms, we basically run a web service that allows you to
            register, submit data, upload voice data, and share results with
            others. We run Google Analytics and collect payment information. We
            do not analyze your audio in an attempt to identify it, sell it, or
            use it in any way other than to provide and improve our service. But
            here's the legalese:
            {/* From https://app.termly.io/document/privacy-policy/772c10f5-4a8b-401e-b00a-c3cba9760652 */}
            <h1>Privacy Statement</h1>
            <p>
              This privacy notice for Learning Machines, Inc (doing business as
              Storyteller) ("Storyteller," "we," "us," or "our"), describes how
              and why we might collect, store, use, and/or share ("process")
              your information when you use our services ("Services"), such as
              when you:
            </p>
            <ul>
              <li>
                Visit our website at {domain.link}, or any website of ours that
                links to this privacy notice
              </li>

              <li>
                Download and use our mobile application ({domain.titlePart}), or
                any other application of ours that links to this privacy notice
              </li>

              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <p>
              <strong>Questions or concerns?</strong> Reading this privacy
              notice will help you understand your privacy rights and choices.
              If you do not agree with our policies and practices, please do not
              use our Services. If you still have any questions or concerns,
              please contact us at <code>support@storyteller.io</code>.
            </p>
            <h3>SUMMARY OF KEY POINTS</h3>
            <p>
              <strong>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our table
                of contents below to find the section you are looking for.{" "}
                {/*You can also click here to go directly to our table of contents.*/}
              </strong>
            </p>
            <p>
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with Storyteller and the
              Services, the choices you make, and the products and features you
              use. {/*Click here to learn more.*/}
            </p>
            <p>
              <strong>Do we process any sensitive personal information?</strong>{" "}
              We do not process sensitive personal information.
            </p>
            <p>
              <strong>Do we receive any information from third parties?</strong>{" "}
              We do not receive any information from third parties.
            </p>
            <p>
              <strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so.{" "}
              {/*Click here to learn more.*/}
            </p>
            <p>
              <strong>
                In what situations and with which types of parties do we share
                personal information?
              </strong>{" "}
              We may share information in specific situations and with specific
              categories of third parties. {/*Click here to learn more.*/}
            </p>
            <p>
              <strong>How do we keep your information safe?</strong> We have
              organizational and technical processes and procedures in place to
              protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.{" "}
              {/*Click here to learn more.*/}
            </p>
            <p>
              <strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you
              have certain rights regarding your personal information.{" "}
              {/*Click here to learn more.*/}
            </p>
            <p>
              <strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by filling out our data subject request
              form available here, or by contacting us. We will consider and act
              upon any request in accordance with applicable data protection
              laws.
            </p>
            {/*<p>Want to learn more about what Storyteller does with any information we collect? Click here to review the notice in full.</p>*/}
            {/*TABLE OF CONTENTS

1. WHAT INFORMATION DO WE COLLECT?
2. HOW DO WE PROCESS YOUR INFORMATION?
3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
7. HOW LONG DO WE KEEP YOUR INFORMATION?
8. HOW DO WE KEEP YOUR INFORMATION SAFE?
9. DO WE COLLECT INFORMATION FROM MINORS?
10. WHAT ARE YOUR PRIVACY RIGHTS?
11. CONTROLS FOR DO-NOT-TRACK FEATURES
12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
13. DO WE MAKE UPDATES TO THIS NOTICE?
14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
  15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?*/}
            <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
            <h5>Personal information you disclose to us</h5>
            <p>
              <em>
                <strong>In Short</strong>: We collect personal information that
                you provide to us.
              </em>
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </p>
            <p>
              Personal Information Provided by You. The personal information
              that we collect depends on the context of your interactions with
              us and the Services, the choices you make, and the products and
              features you use. The personal information we collect may include
              the following:
            </p>
            <ul>
              <li>email addresses</li>

              <li>usernames</li>

              <li>passwords</li>

              <li>contact preferences</li>

              <li>debit/credit card numbers</li>

              <li>names</li>
            </ul>
            <p>
              Sensitive Information. We do not process sensitive information.
            </p>
            <p>
              Payment Data. We may collect data necessary to process your
              payment if you make purchases, such as your payment instrument
              number, and the security code associated with your payment
              instrument. All payment data is stored by Stripe. You may find
              their privacy notice link(s) here:{" "}
              <code>https://stripe.com/privacy</code>.
            </p>
            <p>
              Social Media Login Data. We may provide you with the option to
              register with us using your existing social media account details,
              like your Facebook, Twitter, or other social media account. If you
              choose to register in this way, we will collect the information
              described in the section called "HOW DO WE HANDLE YOUR SOCIAL
              LOGINS?" below.
            </p>
            <p>
              Application Data. If you use our application(s), we also may
              collect the following information if you choose to provide us with
              access or permission:
            </p>
            <ul>
              <li>
                Mobile Device Data. We automatically collect device information
                (such as your mobile device ID, model, and manufacturer),
                operating system, version information and system configuration
                information, device and application identification numbers,
                browser type and version, hardware model Internet service
                provider and/or mobile carrier, and Internet Protocol (IP)
                address (or proxy server). If you are using our application(s),
                we may also collect information about the phone network
                associated with your mobile device, your mobile device’s
                operating system or platform, the type of mobile device you use,
                your mobile device’s unique device ID, and information about the
                features of our application(s) you accessed.
              </li>
            </ul>
            <p>
              This information is primarily needed to maintain the security and
              operation of our application(s), for troubleshooting, and for our
              internal analytics and reporting purposes.
            </p>
            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <h5>Information automatically collected</h5>
            <p>
              In Short: Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </p>
            <p>
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
            <p>The information we collect includes:</p>
            <ul>
              <li>
                Log and Usage Data. Log and usage data is service-related,
                diagnostic, usage, and performance information our servers
                automatically collect when you access or use our Services and
                which we record in log files. Depending on how you interact with
                us, this log data may include your IP address, device
                information, browser type, and settings and information about
                your activity in the Services (such as the date/time stamps
                associated with your usage, pages and files viewed, searches,
                and other actions you take such as which features you use),
                device event information (such as system activity, error reports
                (sometimes called "crash dumps"), and hardware settings).
              </li>

              <li>
                Device Data. We collect device data such as information about
                your computer, phone, tablet, or other device you use to access
                the Services. Depending on the device used, this device data may
                include information such as your IP address (or proxy server),
                device and application identification numbers, location, browser
                type, hardware model, Internet service provider and/or mobile
                carrier, operating system, and system configuration information.
              </li>

              <li>
                Location Data. We collect location data such as information
                about your device's location, which can be either precise or
                imprecise. How much information we collect depends on the type
                and settings of the device you use to access the Services. For
                example, we may use GPS and other technologies to collect
                geolocation data that tells us your current location (based on
                your IP address). You can opt out of allowing us to collect this
                information either by refusing access to the information or by
                disabling your Location setting on your device. However, if you
                choose to opt out, you may not be able to use certain aspects of
                the Services.
              </li>
            </ul>
            <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
            <p>
              <strong>In Short:</strong> We process your information to provide,
              improve, and administer our Services, communicate with you, for
              security and fraud prevention, and to comply with law. We may also
              process your information for other purposes with your consent.
            </p>
            <p>
              <strong>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </strong>
            </p>
            <ul>
              <li>
                <strong>
                  To facilitate account creation and authentication and
                  otherwise manage user accounts.
                </strong>{" "}
                We may process your information so you can create and log in to
                your account, as well as keep your account in working order.
              </li>

              <li>
                <strong>To fulfill and manage your orders.</strong> We may
                process your information to fulfill and manage your orders,
                payments, returns, and exchanges made through the Services.
              </li>

              <li>
                <strong>To request feedback.</strong> We may process your
                information when necessary to request feedback and to contact
                you about your use of our Services.
              </li>

              <li>
                <strong>
                  To send you marketing and promotional communications.
                </strong>{" "}
                We may process the personal information you send to us for our
                marketing purposes, if this is in accordance with your marketing
                preferences. You can opt out of our marketing emails at any
                time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?"
                below).
              </li>

              <li>
                <strong>To protect our Services.</strong> We may process your
                information as part of our efforts to keep our Services safe and
                secure, including fraud monitoring and prevention.
              </li>

              <li>
                <strong>To identify usage trends.</strong> We may process
                information about how you use our Services to better understand
                how they are being used so we can improve them.
              </li>

              <li>
                <strong>
                  To save or protect an individual's vital interest.
                </strong>{" "}
                We may process your information when necessary to save or
                protect an individual’s vital interest, such as to prevent harm.
              </li>
            </ul>
            <h3>
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </h3>
            <p>
              <strong>In Short</strong>: We only process your personal
              information when we believe it is necessary and we have a valid
              legal reason (i.e., legal basis) to do so under applicable law,
              like with your consent, to comply with laws, to provide you with
              services to enter into or fulfill our contractual obligations, to
              protect your rights, or to fulfill our legitimate business
              interests.
            </p>
            <p>
              <strong>
                <u>
                  If you are located in the EU or UK, this section applies to
                  you.
                </u>
              </strong>
            </p>
            <p>
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </p>
            <ul>
              <li>
                <strong>Consent.</strong> We may process your information if you
                have given us permission (i.e., consent) to use your personal
                information for a specific purpose. You can withdraw your
                consent at any time. {/*Click here to learn more.*/}
              </li>

              <li>
                <strong>Performance of a Contract.</strong> We may process your
                personal information when we believe it is necessary to fulfill
                our contractual obligations to you, including providing our
                Services or at your request prior to entering into a contract
                with you.
              </li>

              <li>
                <strong>Legitimate Interests.</strong> We may process your
                information when we believe it is reasonably necessary to
                achieve our legitimate business interests and those interests do
                not outweigh your interests and fundamental rights and freedoms.
                For example, we may process your personal information for some
                of the purposes described in order to:
                <ul>
                  <li>
                    Send users information about special offers and discounts on
                    our products and services
                  </li>

                  <li>
                    Analyze how our Services are used so we can improve them to
                    engage and retain users
                  </li>

                  <li>
                    Diagnose problems and/or prevent fraudulent activities
                  </li>

                  <li>
                    Understand how our users use our products and services so we
                    can improve user experience
                  </li>
                </ul>
              </li>

              <li>
                <strong>Legal Obligations.</strong> We may process your
                information where we believe it is necessary for compliance with
                our legal obligations, such as to cooperate with a law
                enforcement body or regulatory agency, exercise or defend our
                legal rights, or disclose your information as evidence in
                litigation in which we are involved.
              </li>

              <li>
                <strong>Vital Interests.</strong> We may process your
                information where we believe it is necessary to protect your
                vital interests or the vital interests of a third party, such as
                situations involving potential threats to the safety of any
                person.
              </li>
            </ul>
            <p>
              <strong>
                <u>
                  If you are located in Canada, this section applies to you.
                </u>
              </strong>
            </p>
            <p>
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can
              withdraw your consent at any time. {/*Click here to learn more.*/}
            </p>
            <p>
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </p>
            <ul>
              <li>
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </li>

              <li>For investigations and fraud detection and prevention</li>

              <li>
                For business transactions provided certain conditions are met
              </li>

              <li>
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
              </li>

              <li>
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </li>

              <li>
                If we have reasonable grounds to believe an individual has been,
                is, or may be victim of financial abuse
              </li>

              <li>
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </li>

              <li>
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </li>

              <li>
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </li>

              <li>
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </li>

              <li>
                If the information is publicly available and is specified by the
                regulations
              </li>
            </ul>
            <h3>
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h3>
            <p>
              <em>
                <strong>In Short</strong>: We may share information in specific
                situations described in this section and/or with the following
                categories of third parties.
              </em>
            </p>
            <p>
              <strong>
                Vendors, Consultants, and Other Third-Party Service Providers.
              </strong>{" "}
              We may share your data with third-party vendors, service
              providers, contractors, or agents ("<strong>third parties</strong>
              ") who perform services for us or on our behalf and require access
              to such information to do that work. The categories of third
              parties we may share personal information with are as follows:
            </p>
            <ul>
              <li>Cloud Computing Services</li>

              <li>Data Analytics Services</li>

              <li>Payment Processors</li>

              <li>Performance Monitoring Tools</li>

              <li>User Account Registration & Authentication Services</li>
            </ul>
            <p>
              We also may need to share your personal information in the
              following situations:
            </p>
            <ul>
              <li>
                <strong>Business Transfers</strong>. We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>

              <li>
                <strong>Affiliates</strong>. We may share your information with
                our affiliates, in which case we will require those affiliates
                to honor this privacy notice. Affiliates include our parent
                company and any subsidiaries, joint venture partners, or other
                companies that we control or that are under common control with
                us.
              </li>
            </ul>
            <h3>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
            <p>
              <em>
                <strong>In Short</strong>: We may use cookies and other tracking
                technologies to collect and store your information.
              </em>
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information.{" "}
              {/*Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice. */}
            </p>
            <h3>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
            <p>
              <em>
                <strong>In Short</strong>: If you choose to register or log in
                to our Services using a social media account, we may have access
                to certain information about you.
              </em>
            </p>
            <p>
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.
            </p>
            <p>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.
            </p>
            <h3>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
            <p>
              <em>
                <strong>In Short</strong>: We keep your information for as long
                as necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.
              </em>
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
            <h3>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
            <p>
              <em>
                <strong>In Short</strong>: We aim to protect your personal
                information through a system of organizational and technical
                security measures.
              </em>
            </p>
            <p>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
            <h3>9. DO WE COLLECT INFORMATION FROM MINORS?</h3>
            <p>
              <em>
                <strong>In Short</strong>: We do not knowingly collect data from
                or market to children under 18 years of age.
              </em>
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at{" "}
              <code>support@storyteller.io</code>.
            </p>
            <h3>10. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
            <p>
              <em>
                <strong>In Short</strong>: In some regions, such as the European
                Economic Area (EEA), United Kingdom (UK), and Canada, you have
                rights that allow you greater access to and control over your
                personal information. You may review, change, or terminate your
                account at any time.
              </em>
            </p>
            <p>
              In some regions (like the EEA, UK, and Canada), you have certain
              rights under applicable data protection laws. These may include
              the right (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. You can make such a request by contacting us by using
              the contact details provided in the section "HOW CAN YOU CONTACT
              US ABOUT THIS NOTICE?" below.
            </p>
            <p>
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </p>
            <p>
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </p>
            <p>
              If you are located in Switzerland, the contact details for the
              data protection authorities are available here:
              https://www.edoeb.admin.ch/edoeb/en/home.html.
            </p>
            <p>
              <strong>
                <u>Withdrawing your consent</u>
              </strong>
              : If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending
              on the applicable law, you have the right to withdraw your consent
              at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the section
              "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below or updating your
              preferences.
            </p>
            <p>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <h5>Account Information</h5>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul>
              <li>Contact us using the contact information provided.</li>

              <li>
                Log in to your account settings and update your user account.
              </li>
            </ul>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </p>
            <p>
              Cookies and similar technologies: Most Web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Services. To opt out of
              interest-based advertising by advertisers on our Services visit
              http://www.aboutads.info/choices/.
            </p>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at <code>support@storyteller.io</code>.
            </p>
            <h3>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
            <h3>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
            <p>
              <strong>In Short</strong>: Yes, if you are a resident of
              California, you are granted specific rights regarding access to
              your personal information.
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.,
              backups, etc.).
            </p>
            <h4>CCPA Privacy Notice</h4>
            <p>The California Code of Regulations defines a "resident" as:</p>
            {/* TODO: REMOVE BULLET POINT STYLE */}
            <ol>
              <li>
                (1) every individual who is in the State of California for other
                than a temporary or transitory purpose and
              </li>
              <li>
                (2) every individual who is domiciled in the State of California
                who is outside the State of California for a temporary or
                transitory purpose
              </li>
            </ol>
            <p>All other individuals are defined as "non-residents."</p>
            <p>
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
            <p>
              <strong>
                What categories of personal information do we collect?
              </strong>
            </p>
            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <table>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
              <tr>
                <td>A. Identifiers</td>

                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>

                <td>YES</td>
              </tr>
              <tr>
                <td>
                  B. Personal information categories listed in the California
                  Customer Records statute
                </td>

                <td>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>

                <td>YES</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under California
                  or federal law
                </td>

                <td>Gender and date of birth</td>

                <td>NO</td>
              </tr>
              <tr>
                <td>D. Commercial information</td>

                <td>
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>

                <td>NO</td>
              </tr>
              <tr>
                <td>E. Biometric information</td>

                <td>Fingerprints and voiceprints</td>

                <td>NO</td>
              </tr>
              <tr>
                <td>F. Internet or other similar network activity</td>

                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>

                <td>NO</td>
              </tr>
              <tr>
                <td>G. Geolocation data</td>

                <td>Device location</td>

                <td>NO</td>
              </tr>
              <tr>
                <td>
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </td>

                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>

                <td>YES</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information</td>

                <td>
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>

                <td>NO</td>
              </tr>
              <tr>
                <td>J. Education Information</td>

                <td>Student records and directory information</td>

                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from other personal information</td>

                <td>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics
                </td>

                <td>NO</td>
              </tr>
            </table>
            <p>
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </p>
            <ul>
              <li>Receiving help through our customer support channels;</li>

              <li>Participation in customer surveys or contests; and</li>

              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>
            <p>
              <strong>
                How do we use and share your personal information?
              </strong>
            </p>
            <p>
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </p>
            <p>
              You may contact us by email at <code>support@storyteller.io</code>
              , or by referring to the contact details at the bottom of this
              document.
            </p>
            <p>
              If you are using an authorized agent to exercise your right to opt
              out we may deny a request if the authorized agent does not submit
              proof that they have been validly authorized to act on your
              behalf.
            </p>
            <p>
              <strong>Will your information be shared with anyone else?</strong>
            </p>
            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </p>
            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal information.
            </p>
            <p>
              Learning Machines, Inc has not sold any personal information to
              third parties for a business or commercial purpose in the
              preceding twelve (12) months. Learning Machines, Inc has disclosed
              the following categories of personal information to third parties
              for a business or commercial purpose in the preceding twelve (12)
              months:
            </p>
            <ul>
              <li>
                Category A. Identifiers, such as contact details like your real
                name, alias, postal address, telephone or mobile contact number,
                unique personal identifier, online identifier, Internet Protocol
                address, email address, and account name.
              </li>

              <li>
                Category B. Personal Information, as defined in the California
                Customer Records law, such as your name, contact information,
                education, employment, employment history, and financial
                information.
              </li>
            </ul>
            <p>
              The categories of third parties to whom we disclosed personal
              information for a business or commercial purpose can be found
              under "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?".
            </p>
            <p>
              <strong>Your rights with respect to your personal data</strong>
            </p>
            <p>
              <u>Right to request deletion of the data — Request to delete</u>
            </p>
            <p>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </p>
            <p>
              <u>Right to be informed — Request to know</u>
            </p>
            <p>Depending on the circumstances, you have a right to know:</p>
            <ul>
              <li>whether we collect and use your personal information;</li>

              <li>the categories of personal information that we collect;</li>

              <li>
                the purposes for which the collected personal information is
                used;
              </li>

              <li>
                whether we sell your personal information to third parties;
              </li>

              <li>
                the categories of personal information that we sold or disclosed
                for a business purpose;
              </li>

              <li>
                the categories of third parties to whom the personal information
                was sold or disclosed for a business purpose; and
              </li>

              <li>
                the business or commercial purpose for collecting or selling
                personal information.
              </li>
            </ul>
            <p>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </p>
            <p>
              <u>
                Right to Non-Discrimination for the Exercise of a Consumer’s
                Privacy Rights
              </u>
            </p>
            <p>
              We will not discriminate against you if you exercise your privacy
              rights.
            </p>
            <p>
              <u>Verification process</u>
            </p>
            <p>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g., phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </p>
            <p>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </p>
            <p>
              <u>Other privacy rights</u>
            </p>
            <ul>
              <li>
                You may object to the processing of your personal information.
              </li>

              <li>
                You may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the information.
              </li>

              <li>
                You can designate an authorized agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorized agent that does not submit proof that they have been
                validly authorized to act on your behalf in accordance with the
                CCPA.
              </li>

              <li>
                You may request to opt out from future selling of your personal
                information to third parties. Upon receiving an opt-out request,
                we will act upon the request as soon as feasibly possible, but
                no later than fifteen (15) days from the date of the request
                submission.
              </li>
            </ul>
            <p>
              To exercise these rights, you can contact us by email at{" "}
              <code>support@storyteller.io</code>, or by referring to the
              contact details at the bottom of this document. If you have a
              complaint about how we handle your data, we would like to hear
              from you.
            </p>
            <h3>13. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
            <p>
              <strong>In Short</strong>: Yes, we will update this notice as
              necessary to stay compliant with relevant laws.
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
            <h3>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
            <p>
              If you have questions or comments about this notice, you may email
              us at <code>support@storyteller.io</code>
            </p>
            <h3>
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h3>
            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information, please{" "}
              {/*submit a request form by clicking here*/} contact us at{" "}
              <code>support@storyteller.io</code>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { PrivacyPage };
