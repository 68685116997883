export const RandomTexts = [
  "Hello, world!",
  "Welcome to the future.",
  "The quick brown fox jumps over the lazy dog.",
  "In a hole in the ground there lived a hobbit.",
  "Once upon a time in a land far, far away.",
  "Can't believe you've done this.",
  "Sometimes, I wish I was an octopus, so I could slap eight people at once.",
  "Explaining why one is right is not arguing, it's just clarifying.",
  "Wallets are like onions; opening them brings tears.",
  "I'm not lazy, I'm on energy-saving mode.",
  "When life gives you melons, dyslexia might be involved.",
  "Atoms are untrustworthy; they make up everything.",
  "Glue history books are impossible to put down.",
  "Time travel jokes are funny in any era, but you might have missed it.",
  "I would lose weight, but I hate losing.",
  "A messy room is just an open-air museum.",
  "Trying to fail and succeeding is a real conundrum.",
  "Procrastination is just engaging in numerous side quests.",
  "Being early for tomorrow is a new kind of punctuality.",
  "Adulthood is the longest test run that never ends.",
  "Beauty sleep's advertising is misleading.",
  "I'm on a whiskey diet. I've lost three days already.",
  "Magical beds remember all forgotten tasks.",
  "Pillows are the unsung heroes of hairstyling.",
  "I'm not saying I'm Batman, but have you ever seen me and Batman in the same room?",
  "Couches are the battlegrounds for avoiding problems.",
  "Life's brevity demands self-laughter or outsourcing it.",
  "Being a limited edition is far from weird.",
  "Office chairs that spin make the job worth it.",
  "Wake-up time defines personhood, not the time of day.",
  "Math problems multiply in silence.",
  "Physical conservatism is the new fitness trend.",
  "Extra fries misunderstood as exercise is a common error.",
  "I'm fluent in three languages: English, sarcasm, and profanity.",
  "Multitasking involves listening, ignoring, and forgetting simultaneously.",
  "Indecisiveness is a choice, sometimes.",
  "I'm really good at stuff until people watch me do that stuff.",
  "Correctness needs no argument, just explanation.",
  "Coffee relationships are the most committed ones.",
  "Rush hour is the greatest paradox.",
  "I'm not bossy. I just have better ideas.",
  "Silence and duct tape both have their merits.",
  "I'm not procrastinating. I'm under a lot of pressure and very well could be a diamond soon.",
  "Battery saver mode is essential for work avoidance.",
  "Exploration is just being lost with style.",
  "Pressing harder on a remote control seems like a valid effort when batteries are weak.",
  "I'm convinced that bugs in software are just features in disguise.",
  "Adulthood is getting excited about a new sponge for the kitchen, I've accepted this.",
  "Life's too short, I always smile while I still have all my teeth.",
  "In video games, I've learned that encountering enemies often means you're going in the right direction.",
  "I'm often on the hunt for motivation, but it's like searching for a 404 error.",
  "Debating with a computer, I've realized, is as effective as trying to solve algebra by chewing bubblegum.",
  "During rush hour, I remind myself that patience is indeed a virtue.",
  "I transformed a shy friend into a social butterfly, and now I wonder what I've unleashed.",
  "The quest for sleep, sanity, and a peaceful hobbit life is something I'm still on.",
  "My bed has a magical ability to remind me of forgotten tasks at night.",
  "When the Wi-Fi goes down, I discover family members are actually nice people.",
  "A cookie in each hand, I believe, is the true definition of a balanced diet.",
  "Why use Google when I'm convinced someone believes they have all the answers?",
  "Exploring the great indoors, I've found, is because outside is overrated.",
  "I'm fluent in three languages: English, sarcasm, and late-night coding.",
  "Why wait for a time machine when I have memories and music?",
  "The best way to avoid chores, I've found, is to pretend not to know they exist.",
  "If I can't find the sunshine, I've learned to be the person who switches on the light.",
  "Realizing too late that adulthood is a trap is a lesson I've learned the hard way.",
  "The art of being wise, I've discovered, is knowing what to overlook, like unread emails.",
  "Why call it fast food if I have to wait in line for it?",
  "I've discovered that my spirit animal is a sloth on weekdays.",
  "Making a 'quick' phone call, I've realized, is never quick.",
  "The mystery of how one sock always disappears in the laundry remains unsolved, I've noticed.",
  "Finding out that my emergency contact is a pizza delivery service was a surprise.",
  "The realization that autocorrect has become my worst enema... I mean, enemy, is frustrating.",
  "Wondering why my coffee tastes like I should have made it myself is a daily ritual.",
  "The silent joy of canceling plans is an underrated pleasure I cherish.",
  "Discovering that my daily workout is trying to dodge spoilers online is a modern struggle.",
  "Realizing that my phone's battery life lasts longer than my motivation is a harsh reality.",
  "The paradox of being a perfectionist procrastinator is a real struggle I face.",
  "Finding comfort in the fact that chocolate understands me is a sweet relief.",
  "Realizing I'm not late, I'm just on a creative time schedule is how I cope.",
  "Discovering that my hobby is collecting dust on my gym membership was an eye-opener.",
  "The irony of being a tech expert but unable to fix my sleep schedule is not lost on me.",
  "Understanding that my to-do list is more of a suggestion than a requirement helps me sleep at night.",
  "The thrill of solving a bug in code is the coder's version of a plot twist, I've found.",
  "Realizing that my idea of a balanced diet is having a piece of tech in both hands is a modern dilemma.",
];

export const PlaceholderTexts = [
  "Type here and hear it in your favorite voice.",
  "What would you like to hear? Type it in!",
  "Your words, different voices. Start typing...",
  "Enter text, get speech. Try it out!",
  "Say it without speaking. Type something!",
  "Make this voice say what you want.",
  "Type a message and press play.",
  "Imagine it, then type it. Hear it out loud.",
  "Your text's voiceover starts here. Go ahead.",
  "Enter the text you want the voice to say here...",
  "Try using this voice to make a custom greeting, away message, or something special for your friends.",
  "Your words, our voice. Create a personal message now.",
  "Draft a heartfelt note and let the voice deliver it.",
  "Envision your story being told. Start typing here.",
  "Got a script? Type it, and we'll voice it for you.",
  "Craft an inspiring quote and listen to it in any voice.",
  "Compose a catchy slogan and hear how it sounds aloud.",
  "Enter the text you want the voice to say here...",
  "You can make a video, animation, or any content you want with your favorite voices.",
];
