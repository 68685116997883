import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons'

export function PlayIcon() {
  return (
      <FontAwesomeIcon icon={faPlay} title="Play" />
  );
}

