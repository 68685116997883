import React, {
  useRef,
  useCallback,
  useReducer,
} from "react";

import { VideoFakeyou } from "components/common";
import { VideoFakeyouProps } from "../VideoFakeyou/VideoFakeyou";

import {
  reducer,
  initialState,
  ACTION_TYPES
} from "./reducer";
import {
  VideoElementContext
} from "./contexts";

import { VideoTakeover } from "./components/VideoTakeover";
import { ScrubberBar } from "./components/ScrubberBar";
import { ControlBar } from "./components/ControlBar";

import './styles.scss';


interface VideoPlayerProps extends VideoFakeyouProps {
  debug?: boolean
  height?: number
}

export const VideoPlayer = ({
  debug: propsDebug = false,
  height=500,
  ...rest
}: VideoPlayerProps) => {
  const debug = false; // || propsDebug;
  if(debug) console.log(`reRENDER VideoPlayer @${String(Date.now()).slice(-5)}`)

  const [compState, dispatchCompState] = useReducer(reducer, initialState);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoRefCallback = useCallback(node => {
    function handleLoadedmetadata (){
      dispatchCompState({
        type: ACTION_TYPES.ON_LOADED_METADATA,
        payload:{ videoDuration: node.duration,}
      });
    };
    if (node !== null) {
      // DOM node referenced by ref has changed and exists
      videoRef.current = node;
      node.addEventListener("loadedmetadata", handleLoadedmetadata);
    } // else{} DOM node referenced by ref has been unmounted

    return()=>{
        node?.removeEventListener("loadedmetadata",handleLoadedmetadata);
    }

  }, [
    // No Dependency !
  ]); //END videoRefCallback\

  return(
    <VideoElementContext.Provider value={videoRef.current}>
      <div className="fy-video-player">
        <div className="video-wrapper">
          <VideoFakeyou
            debug={false}
            height={height}
            controls={false}
            // muted={compState.isMuted}
            ref={videoRefCallback}
            {...rest}
          />

          {/* components that takes over for spinner and warning message */}
          <VideoTakeover height={height} status={compState.status}/>
        </div>{/* END of Video Wrapper */}
        <ScrubberBar status={compState.status}/>
        <ControlBar debug={debug} status={compState.status}/>
      </div>
    </VideoElementContext.Provider>
  );
}

